import {defineStore} from 'pinia';
import {ref} from 'vue';
import {useServerFetch} from '../composables/useServerFetch.js';
import {useLocaleStore} from './LocaleStore.js';
import {useBreadcrumbStore} from './BreadcrumbStore.js';


export const useRouterStore = defineStore('router', () => {
    const pages = ref([]);

    async function addPage(data) {
        if (!hasPage(data.url)) {
            pages.value.push(data);
        }
    }

    function hasPage(url) {
        return pages.value.findIndex(item => item.url === url) >= 0;
    }

    //no usages
    // function deletePage(url) {
    //     const index = pages.value.findIndex(item => item.url === url);
    //     if (index >= 0) {
    //         pages.value.splice(index, 1);
    //     }
    //
    //     return this;
    // }

    function getPage(url) {
        return pages.value.find(function (item) {
            let page = item.url === url;
            if (page) {
                return page;
            }

            return pages.value.find(item => url === item.url.replace(/^\/[^\/]*\//, '/'));
        });
    }

    async function serverRoute(url, alias = null) {
        let pageType = null;
        const locale = useLocaleStore().prefix;
        const langUrl = '/' + locale + (alias?alias:url);
        if (hasPage(langUrl)) {
            pageType = getPage(langUrl);
        } else {
            const { data, error, pending, response } =  await useServerFetch(url, {});
            pageType = data.value?.data;
            pageType.url = langUrl;

            await addPage(pageType);
        }

        useBreadcrumbStore().items = pageType?.breadcrumbs;
        return pageType;
    }

    function getCurrentPage() {
        return getPage(useRoute().path);
    }

    return {
        pages,
        addPage,
        hasPage,
        getPage,
        serverRoute,
        getCurrentPage
    };
});
